import React from 'react';
import { NavLink } from 'react-router-dom'

const mainMenu = [
  {
    "name": "Мониторинг",
    "key": "menu_mon",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "Данные НАП",
        "key": "menu_napdata",
        "icon": "icon-organization",
        "action": "/napdata"
      },
      {
        "name": "Связь КНАП",
        "key": "menu_knapdata",
        "icon": "icon-organization",
        "action": "/knapdata"
      }
    ]
  },
  {
    "name": "Сервисы",
    "key": "menu_ser",
    "icon": "icon-organization",
    "submenu": [
      {
        "name": "ГЛОНАСС-ЭРА",
        "key": "menu_control",
        "icon": "icon-organization",
        "action": "/module_glonass_era_egts"
      },
      {
        "name": "А-ГНСС",
        "key": "menu_admin",
        "icon": "icon-organization",
        "action": "/agnss"
      },
      {
        "name": "РРР",
        "key": "menu_PPP",
        "icon": "icon-organization",
        "action": "/ppp"
      },
      {
        "name": "Внешние системы",
        "key": "menu_sys",
        "icon": "icon-organization",
        "action": "/outsystem"
      }
    ]
  }
]

const MenuItems = (props) => {
  if (props.items === null) return null;
  console.log(props.items);
  let v_ret = props.items.map(function (item, index) {
    if ("submenu" in item)
      return (
        <li key={item.key} className="dropdown"><a href="#"><i className={"me-2 " + item.icon}></i>{item.name}</a>
          <ul>
            <MenuItems items={item.submenu} />

          </ul>
        </li>
      );
    else
      return (
        <li key={item.key}><NavLink to={item.action}><i className={"me-2 " + item.icon}></i>{item.name}</NavLink></li>
      );
  });
  return v_ret;
}

const Menu = (props) => {
  console.log(props.menu);
  return (
    <div className="sidebar pt-0" data-bs-theme={(props.settings.themeColor ?? "light") === "light" ? "light" : "dark"}>
      <div className="site-width">
        <ul id="side-menu" className="sidebar-menu">
          {/* <MenuItems items={props.menu} /> */}
          <MenuItems items={mainMenu} />
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Menu);
