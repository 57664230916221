import moment from "moment/moment";
import { useState } from "react";
import CardHeaderDashboard from "../home/cardHeaderDashboard";
import { MainArea } from "../layout/MainArea";



// auth-user.alter.ficom-it.info
// test-incident-resolve.alter.ficom-it.info
// use-info-systems.alter.ficom-it.info

const SchPuLsn = (props) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();
  const [date, setDate] = useState();

  let services = [
    { id: 1, name: "АСМ ЭРА", status: 1, date: date },
    { id: 2, name: "КНАП", status: 1, date: date },
  ];

  const handleClick = (event) => {
    if (loading) return;
    event.preventDefault();
    event.target.blur();
    setDate((new Date()).toUTCString());
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  // useAsyncEffect(() => {
  //   getOrdersClientByID(token, urlSearch?.order_id).then((e) => {
  //     if (!e.data.errors) {
  //       setRace(e.data.data?.race);
  //       setElement(e?.data.data.product);
  //       setOrder(e?.data.data);
  //     }
  //   });
  // }, []);

  let styleCard = {};
  let classCard = "card h-100";
  if (props.dashboard === false) {
    styleCard = { minHeight: "var(--clientheight)" };
    classCard = "card border-0";
  }

  return (
    <MainArea>

<div className="card-body ">

    <div className={classCard} style={styleCard}>
      <CardHeaderDashboard loading={loading} onClick={handleClick}>
       СЧ ПУ-ЛСН
      </CardHeaderDashboard>
      <div className="card-body" style={{ fontSize: "0.85rem" }}>
        {(
            services.map((item, index, array) => {
                return (
                    <div key={item.id} className={`col-12 d-flex align-items-center ${index == 0 ? "" : "mt-1"}`}>
                <i className={`fs-5 me-2 ${item.status == 0 ? "bi-check-circle-fill text_green" : "bi-x-circle-fill text-danger"}`}></i>
                <div>
                  <span className="link-info">
                    {item.name} - <small>{moment(item.date).isValid() ? moment.utc(item.date).local().format("DD.MM.YYYY HH:mm:ss") : item.date}</small>
                  </span>
                </div>
              </div>
            );
        })
        )}
      </div>
    </div>
        </div>
        </MainArea>
  );
}

export default SchPuLsn;