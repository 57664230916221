import { useState, useEffect, useReducer } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $ from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../../utils/appUtils";
import TableDT from "../../../UI/TableDT";
import ModalGlonassEra from "../GlonassEra/ModalGlonassEra";
import { reducerModalWindow } from "../../../UI/ModalWindow";

const ROUTE_URL = "/glonass_egts";


function GlonassEraEgts(props) {
    const { showErrorPage, messageBox } = useOutletContext();
    const navigate = useNavigate();
    const [serverData, setServerData] = useState(null);

    const [paramsModalWindow, dispatchModalWindow] = useReducer(reducerModalWindow, { show: false, mode: "U" });
    const handleAddRow = () => {
        dispatchModalWindow({ type: 'add' });
    };

    const handleCloseModalWindow = (result) => {
        if (result.modalResult === 'mrOk') dtTable.Api.ajax.reload(null, false);
        dispatchModalWindow({ type: 'destroy' });
    };

    const handleAfterDTCreate = ({ DOM, Api }) => {
        dtTable.Api = Api;
        $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            let urlDelete = ROUTE_URL + "/" + data.id;
            messageBox({
                show: true, message: `Вы действительно хотите удалить элемент?`, mbOk: (e) => {
                    e.preventDefault();
                    messageBox({ show: false, mbOk: null });
                    requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
                }
            });
        });
        $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
            e.preventDefault();
            let data = dtTable.Api.row($(this).parents('tr')).data();
            dispatchModalWindow({ type: 'edit', id: data.id });
        });
    }

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true).then(r => { });
    }, []);

    if (serverData === null)
        return <MainArea loading />;

    return (
        <>
            <MainArea>
                <MainAreaHeader caption="ГЛОНАСС-ЭРА" />
                <MainAreaBody containerClass="container-xxl">
                    {
                        (serverData.crud & 8) !== 0 && <div><button type="button" className="btn btn-primary btn-sm btn-our" onClick={handleAddRow}>Добавить</button></div>
                    }
                    <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate} />
                </MainAreaBody>
            </MainArea >
            {paramsModalWindow.show && <ModalGlonassEra params={paramsModalWindow} handleClose={handleCloseModalWindow} />}
        </>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions =
        {
            ordering: true, lengthChange: true, filter: true, paging: false, info: true, processing: true,
            order: [[1, "asc"]],
            dom: getDataTableDom("iflp", 1, 1),
            ajax: { type: "GET", url: ROUTE_URL },
            columns: [
                { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },
                { data: "id", title: "№", className: "dt-body-right dt-head-center", render: DataTable.render.dataToAnchor("btnShowEdit"), },
                { data: "name", title: "название", render: DataTable.render.text(), },
                { data: "adress", title: "Адрес мониторинга", render: DataTable.render.text() },
                { data: "imei", title: "Порт", render: DataTable.render.text() },
                { data: "created_at", title: "Дата создания", render: DataTable.render.date() },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("iflp", 1, 1);
        }
        return dtOptions;
    }
}


export default GlonassEraEgts;