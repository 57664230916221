import { ModalWindow } from "../../../UI/ModalWindow";
import { Input, Select } from '../../../UI/FormElements'

const ROUTE_URL = "/agnss";
const initialData = { adress: "", imei: "", name: "" };

function ModalAGnss({ params, handleClose }) {
  console.log("params", params);
  let urlSubmit, urlGet;
  if (params.isNew) {
    urlSubmit = ROUTE_URL;
    urlGet = ""; //или ROUTE_URL + "/create" если при создании НАДО предварительное обращение к серверу

  }
  else {
    urlGet = ROUTE_URL + "/" + params.id;
    urlSubmit = urlGet;

  }

  console.log(urlGet);
  return (
    <ModalWindow params={params} handleClose={handleClose} initialData={initialData} urlGet={urlGet} urlSubmit={urlSubmit} >
      <IncidentCardsFields />
    </ModalWindow>
  );
}
let categoryTypes = [{ id: 1, name: "Новое" }, { id: 2, name: "В работе" }, { id: 3, name: "Просрочено" }, { id: 4, name: "Выполнено" }, { id: 5, name: "В архиве" }];

const IncidentCardsFields = ({ dopParams, ...elementProps }) => {
  console.log(elementProps);
  return (
    <div className="row form-gutter">
      <div className="col-12">
        <Input label="Адрес мониторинга" name="adress"  {...elementProps} required autoFocus />
      </div>
      <div className="col-12">
        <Input label="IMEI" name="imei" {...elementProps} required />
      </div>
      <div className="col-12">
        <Input label="Название" name="name" {...elementProps} required />
      </div>
    </div>
  )
}
export default ModalAGnss;
