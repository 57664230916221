import { useState, useEffect, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import $, { data } from "jquery";
import DataTable from "datatables.net";
import { MainArea, MainAreaHeader, MainAreaBody } from "../../../layout/MainArea";
import { getDataTableDom, requestToServer } from "../../../utils/appUtils";
import TableDT from "../../../UI/TableDT";
import { MapContainer, Marker, Popup, TileLayer, useMapEvents } from "react-leaflet";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {  useMap } from 'react-leaflet'
import "../KNAP/Knap.css"
import icon from "../../../../images/car-marker.png";
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';



const ROUTE_URL = "/get_conn_knap_last";

function Knapdata(props) {
    clearInterval(window.timer)
  
    const [serverData, setServerData] = useState(true);
    
    const { showErrorPage, messageBox } = useOutletContext();
    const { showErrorPageMap, messageBoxMap } = useOutletContext();

    const navigate = useNavigate();
    // const position = [ 55.751244, 37.618423]
    const [position, setPosition] = useState(null)

    let DefaultIcon = L.icon({
        iconUrl: icon,
        iconSize: [32], iconAnchor: [16, 16],
                // shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;
    L.Marker.prototype.options.keyboard = false;

const refMap = useRef()

const handleAfterDTCreate = ({ DOM, Api }) => {
    dtTable.Api = Api;
    $(DOM).on('click', 'tbody button[name="btnDelete"]', function (e) {
        e.preventDefault();
        let data = dtTable.Api.row($(this).parents('tr')).data();
        let urlDelete = ROUTE_URL + "/" + data.id;
        messageBox({
            show: true, message: `Вы действительно хотите удалить "${data.name}"?`, mbOk: (e) => {
                e.preventDefault();
                messageBox({ show: false, mbOk: null });
                requestToServer(urlDelete, { method: 'DELETE' }, () => { dtTable.Api.ajax.reload(); }, (error) => { alert(JSON.stringify(error)); }, false);
            }
        });
    });
    $(DOM).on('click', 'tbody a[name="btnShowEdit"]', function (e) {
        e.preventDefault();
        let data = dtTable.Api.row($(this).parents('tr')).data();
        let urlStr = "./" + data.id;
        navigate(urlStr);
    });

}

    useEffect(() => {
        requestToServer(ROUTE_URL + "?crud", { method: 'GET' }, setServerData, showErrorPage, true);
        requestToServer(ROUTE_URL + "?track", { method: 'GET' }, setPosition, showErrorPageMap, true);
    }, []);

    useEffect(() => {
        if (position?.data.length) {

            refMap.current.panTo([position.data[0].latitude, position.data[0].longitude])
        }
    }, [position])

    if (serverData === null)
        return <MainArea loading />;

    return (
        <MainArea>
            <MainAreaHeader caption="Связь с КНАП" />
            <div className="card-body">
            <TableDT getOptions={() => dtTable.getTableOptions(serverData.crud)} afterCreate={handleAfterDTCreate}/>

                <MapContainer ref={refMap}  center={{ lat: position?.data[0]?.latitude || 55.751244, lng: position?.data[0]?.longitude || 37.618423 }} zoom={13}>
                    <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {position?.data[0].latitude && position?.data[0]?.longitude && (
                        <Marker position={[position?.data[0]?.latitude, position?.data[0]?.longitude]}></Marker>
                    )}
                </MapContainer>
            </div>        
        </MainArea>
    );
}

let dtTable = {
    Api: null,
    getTableOptions: (crud) => {
        const dtOptions = {
            ordering: true, lengthChange: false, filter: false, paging: false, info: false, processing: true,
            order: [[1, "asc"]],
            ajax: { type: "GET", url: ROUTE_URL + "?track" },
            dom: getDataTableDom("lfip", 2, 1),
            columns: [
                // { orderable: false, searchable: false, data: null, className: "dt-center td-btn-action", render: DataTable.render.btnDelete(), },

                { data: "timestamp", title: "Временная метка", render: DataTable.render.datetime(), },
                { data: "receiver_timestamp", title: "Время отправки", render: DataTable.render.datetime(), },
                { data: "is_valid", title: "Флаг достоверности решения НЗ", render: DataTable.render.number(), },
                { data: "latitude", title: "Широта", render: DataTable.render.text(), },
                { data: "longitude", title: "Долгота", render: DataTable.render.text(), },
                { data: "altitude", title: "Высота над эллипсоидом", render: DataTable.render.number(), },
                { data: "speed", title: "Плановая скорость", render: DataTable.render.number(), },
                { data: "yaw", title: "Курс", render: DataTable.render.number(), },
                { data: "used_psats", title: "Количество ПС в решении", render: DataTable.render.number(), },
                { data: "valid_count", title: "Количество достоверных решений НЗ подряд", render: DataTable.render.number(), },
                { data: "vdop", title: "VDOP", render: DataTable.render.number(), },
                { data: "pdop", title: "PDOP", render: DataTable.render.number(), },
            ],
        };
        if ((crud & 1) === 0) {
            dtOptions.order = [[0, "asc"]];
            dtOptions.columns.splice(0, 1);
            dtOptions.dom = getDataTableDom("lfip", 1, 1);
        }

        console.log('dtOptions', dtOptions);


        return dtOptions;
    }
};

export default Knapdata;
